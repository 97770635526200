<template>
  <div>
    <Header
      back="返回"
      title="工程专利"
      index="首页"
      titleOne="科创模块"
      titleTwo="工程专利"
      titleThree="快捷上传"
      beforeTitle="附件识别上传"
    />
    <div class="content" v-loading="loading" element-loading-text="附件上传中···">
      <h1>附件识别上传</h1>
      <div class="file_look">
        <el-upload
          class="upload_demo"
          ref="imgupload"
          action="string"
          :http-request="httpRequest"
          :show-file-list="false"
          :before-upload="beforeAvatarUpload"
          :file-list="fileList"
        >
          <el-button type="primary" class="ChooseFile">请上传附件</el-button>
        </el-upload>
        <span style="color:#A6ABC7">（上传文件格式支持压缩包zip/rar）</span>
      </div>
      <el-table :data="tableData.data" style="width: 100%;margin-top:24px">
        <el-table-column prop="file_name" label="附件名称" style="max-width:500px"></el-table-column>
        <el-table-column prop="create_time" label="上传时间"></el-table-column>
        <el-table-column prop="finish_time" label="完成时间"></el-table-column>
        <el-table-column prop="status" label="附件">
          <template slot-scope="scope">
            <span v-if="!scope.row.status">未执行</span>
            <span style="color:#5CC038" v-if="scope.row.status">已完成</span>
          </template>
        </el-table-column>
      </el-table>
      <div class="block">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :page-size="10"
          layout="prev, pager, next, jumper"
          :total="tableData.total"
        ></el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data () {
    return {
      loading: false,
      tableData: {},
      fileList: [],
      fileParams: {
        page: 1,
        limit: 10
      }
    }
  },
  created () {
    this.getFileList()
  },
  methods: {
    handleSizeChange (val) {
      this.fileParams.limit = val
      this.axios.get('/api/patent/patent_work_list', this.fileParams).then((res) => {
        this.tableData = res.data
      })
    },
    handleCurrentChange (val) {
      this.fileParams.page = val
      this.axios.get('/api/patent/patent_work_list', this.fileParams).then((res) => {
        this.tableData = res.data
      })
    },
    getFileList () {
      this.axios.get('/api/patent/patent_work_list', { params: { ...this.fileParams } }).then((res) => {
        this.tableData = res.data
      })
    },
    httpRequest (param) {
      let file = param.file
      const isZip = file.name.endsWith('.zip')
      const isRar = file.name.endsWith('.rar')
      const form = new FormData()
      form.append('file', file)
      if (isZip || isRar) {
        this.loading = true
        this.upload('/api/patent/upload_multi_file', form).then((res) => {
          this.$message.success(res.message)
          this.getFileList()
          this.loading = false
        })
      } else {
        this.$message.error('上传格式只能是 zip/rar 格式')
      }
    },
    upload (url, params) {
      return axios
        .post(url, params, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          return new Promise((resolve, reject) => {
            resolve(res);
          });
        })
        .catch((err) => {
          console.log("err:", err);
        });
    },
    // 上传文件前的过滤
    beforeAvatarUpload (file) {
      const isLt20M = file.size / 1024 / 1024 < 20;
      if (!isLt20M) {
        this.$message.error("上传文件大小不能超过 20MB!");
      }
      return isLt20M;
    },
  }
}
</script>

<style scoped>
.file_look {
  display: flex;
  align-items: center;
}
.ChooseFile {
  width: 370px;
  height: 40px;
  background: #edeef4;
  border: none;
  color: #909399;
}
</style>